import React from 'react';
import {getLanguage} from "../../../util/lang";

function ModalDeskTokopedia() {
    const lang = getLanguage();

    const copy = {
        title: "Deskripsi Pekerjaan",
        desc_1: `Saya mengintegrasikan Google Authenticator dengan Tokopedia, memungkinkan pengguna mendapatkan 
                kode OTP secara offline, meningkatkan RPS sebesar 3%.`,
        desc_2: `Saya juga mengintegrasikan beberapa Agen OTP untuk menangani lebih banyak lalu lintas, 
                meningkatkan keandalan OTP dan meningkatkan RPS sebesar 5%.`,
        desc_3: `Saya mengembangkan proyek Desktop Fingerprint untuk mencegah penyalahgunaan dan 
                mengidentifikasi perilaku pengguna.`,
        desc_4: `Saya memimpin pengembangan aplikasi Mitra Tokopedia, memberdayakan warung kecil di 
                Indonesia dan menambahkan sekitar 1 miliar ke pendapatan Tokopedia dalam tiga bulan.`,
        desc_5: `Selama pandemi Covid-19, saya membantu membuat fitur Donasi Tokopedia, memungkinkan 
                pengguna untuk berdonasi dari rumah.`,
        desc_6: `Terakhir, saya melakukan refactoring kode dasar Tokopedia Feed, membuatnya lebih mudah 
                untuk dipelihara dan meningkatkan penambahan fitur di masa depan.`,
        closeButton: "Tutup",
    }

    const copyEn = {
        title: "Job Description",
        desc_1: `I integrated Google Authenticator with Tokopedia, allowing users to receive OTP codes offline, 
                which improved the RPS by 3%.`,
        desc_2: `I also integrated multiple OTP agents to handle higher traffic, enhancing OTP reliability and 
                increasing the RPS by 5%.`,
        desc_3: `I developed the Desktop Fingerprint project to prevent misuse and identify user behavior.`,
        desc_4: `I led the development of the Mitra Tokopedia app, empowering small shops in Indonesia and 
                adding approximately 1 billion to Tokopedia's revenue within three months.`,
        desc_5: `During the COVID-19 pandemic, I helped create the Tokopedia Donation feature, allowing users 
                to make donations from home.`,
        desc_6: `Finally, I refactored the core code of Tokopedia Feed, making it easier to maintain and 
                improving the addition of future features.`,
        closeButton: "Close",
    }

  return (
    <div className="modal modal-desk-tokopedia">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "en" ? copyEn.title : copy.title}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    <i className='bi bi-1-square-fill'></i>&nbsp;
                    {lang == "en" ? copyEn.desc_1 : copy.desc_1} <br />

                    <i className='bi bi-2-square-fill'></i>&nbsp;
                    {lang == "en" ? copyEn.desc_2 : copy.desc_2} <br />

                    <i className='bi bi-3-square-fill'></i>&nbsp;
                    {lang == "en" ? copyEn.desc_3 : copy.desc_3} <br />

                    <i className='bi bi-4-square-fill'></i>&nbsp; 
                    {lang == "en" ? copyEn.desc_4 : copy.desc_4} <br />

                    <i className='bi bi-5-square-fill'></i>&nbsp;
                    {lang == "en" ? copyEn.desc_5 : copy.desc_5} <br />

                    <i className='bi bi-6-square-fill'></i>&nbsp; 
                    {lang == "en" ? copyEn.desc_6 : copy.desc_6}
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-desk-tokopedia">{lang == "en" ? copyEn.closeButton : copy.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default ModalDeskTokopedia;
