import React from 'react';
import {getLanguage} from "../../../util/lang";

function ModalDeskAegis() {
    const lang = getLanguage();

    const copy = {
        title: "Deskripsi Pekerjaan",
        desc: `Saya mengembangkan aplikasi mobile untuk kebutuhan edukasi dari awal, mencakup backend dan frontend. 
            Saya merancang arsitektur backend yang kuat dan membuat antarmuka frontend yang intuitif. 
            Aplikasi ini memiliki fitur seperti materi pembelajaran, kuis interaktif, dan pelacakan 
            kemajuan siswa untuk memberikan pengalaman belajar yang komprehensif.`,
        closeButton: "Tutup",
    }

    const copyEn = {
        title: "Job Description",
        desc: `I developed a mobile application for educational purposes from scratch, covering both backend and frontend. 
        I designed a robust backend architecture and created an intuitive frontend interface. The application includes 
        features such as learning materials, interactive quizzes, and student progress tracking to provide a comprehensive 
        learning experience.`,
        closeButton: "Close",
    }
  return (
    <div className="modal modal-desk-aegis">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "en" ? copyEn.title : copy.title}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    {lang == "en" ? copyEn.desc : copy.desc}
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-desk-aegis">{lang == "en" ? copyEn.closeButton : copy.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default ModalDeskAegis;
