import React from 'react';
import {getLanguage} from "../../../util/lang";

function ModalDeskMetanouva() {
    const lang = getLanguage();

    const copy = {
        title: "Deskripsi Pekerjaan",
        desc: `Saya bekerja sama dengan tim untuk mengembangkan situs web klien, merancang dan mengimplementasikan 
            fitur sesuai kebutuhan klien. Tugas saya meliputi coding, testing, dan penyempurnaan situs web 
            agar sesuai dengan visi dan tujuan klien. Komunikasi efektif dan kerja sama tim sangat penting 
            untuk berhasil menghasilkan situs web berkualitas tinggi yang memenuhi kebutuhan dan ekspektasi klien.`,
        closeButton: "Tutup",
    }

    const copyEn = {
        title: "Job Description",
        desc: `I worked with the team to develop the client's website, designing and implementing features according 
            to the client's needs. My tasks included coding, testing, and refining the website to align with 
            the client's vision and goals. Effective communication and teamwork were crucial to successfully delivering 
            a high-quality website that met the client's needs and expectations.`,
        closeButton: "Close",
    }

  return (
    <div className="modal modal-desk-metanouva">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "en" ? copyEn.title : copy.title}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    {lang == "en" ? copyEn.desc : copy.desc}
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-desk-metanouva">{lang == "en" ? copyEn.closeButton : copy.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default ModalDeskMetanouva;
