import React, { useEffect, useRef } from 'react';
import './Beranda.css';
import {getLanguage} from "../../util/lang";

function wrapWordsInSpan(text, lang) {
    return text.split('').map((char, index) => {
        // if (lang == "id" && index == 8) {
        //     return `&nbsp;`
        // }

        if (lang != "id" && index == 8) {
            return `&nbsp;`
        }

        return `<span style="--i: ${index}">${char}</span>`
    }).join('');
}

function Beranda() {
    const textRef = useRef(null);

    useEffect(() => {
        const textElement = textRef.current;
        const text = textElement.textContent;
        const lang = getLanguage();
        
        // Fungsi untuk menjalankan animasi setiap 5 detik
        const intervalId = setInterval(() => {
            textElement.innerHTML = wrapWordsInSpan(text, lang);
        }, 5000);

        return () => {
            clearInterval(intervalId); // Membersihkan interval saat komponen dibongkar
        };
    }, []);

    const lang = getLanguage();

    const copy = {
        subheading: "Praktisi IT",
        highlight: `Dengan 10+ tahun pengalaman dalam pengembangan perangkat lunak, saya telah mengembangkan berbagai 
                    aplikasi berkualitas tinggi. Keahlian saya mencakup analisis kebutuhan, desain arsitektur, 
                    pengembangan, pengujian, dan implementasi.`,
    }

    const copyEn = {
        subheading: "IT Specialist",
        highlight: `With 10+ years of experience in software development, I have developed a variety of high-quality 
        applications. My expertise includes requirements analysis, architectural design, development, testing, 
        and implementation.`,
    }

    return (
        <section className="section beranda" id="beranda">
            <div className='container'>
                <div className="row gy-4 px-4">
                    <div className="col-lg-12 order-2 order-lg-1 d-flex flex-column justify-content-center" data-aos="zoom-out">
                        <h1 className="mb-0">
                            Husni Firmansyah
                        </h1>
                        <div ref={textRef} className="subheading mb-0">
                            {lang == "en" ? copyEn.subheading : copy.subheading}
                        </div>
                        <h2 className='mb-5'>
                            Perumahan Bumi Marhamah Blok C No. 15 . Cianjur . Jawa Barat 43281
                        </h2>
                        <p className="lead mb-5">
                            {lang == "en" ? copyEn.highlight : copy.highlight}
                        </p>
                        <div className="social-icons d-flex">
                            <a href="https://www.youtube.com/@husni.firmansyah/videos" target='_blank'><i className="bi bi-youtube"></i></a>
                            <a href="https://kelaskoding.id" target='_blank'><i className="bi bi-code-slash"></i></a>
                            <a href="https://www.linkedin.com/in/husni-firmansyah-a7930658/" target='_blank'><i className="bi bi-linkedin"></i></a>
                            <a href="mailto:husni.firmansyah@gmail.com" target='_blank'><i className="bi bi-envelope"></i></a>
                            <a href="https://wa.me/6281224459207" target='_blank'><i className="bi bi-whatsapp"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
  }
  
  export default Beranda;
  