import React from 'react';
import {getLanguage} from "../../../util/lang";

function ModalDeskAce() {
    const lang = getLanguage();

    const copy = {
        title: "Deskripsi Pekerjaan",
        desc: `Saya mengembangkan situs web profil perusahaan dari awal bersama tim, menangani backend dan frontend. 
            Saya membangun arsitektur backend yang kuat untuk penanganan data yang efisien dan membuat 
            antarmuka frontend yang menarik untuk merepresentasikan merek perusahaan. Bekerja sama dengan 
            pemangku kepentingan, saya memastikan situs web sesuai dengan identitas dan tujuan perusahaan.`,
        closeButton: "Tutup",
    }

    const copyEn = {
        title: "Job Description",
        desc: `I developed the company profile website from scratch with a team, handling both backend and frontend. 
            I built a robust backend architecture for efficient data handling and created an engaging frontend interface 
            to represent the company's brand. Working with stakeholders, I ensured the website aligned with 
            the company's identity and objectives.`,
        closeButton: "Close",
    }
  return (
    <div className="modal modal-desk-ace">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "en" ? copyEn.title : copy.title}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    {lang == "en" ? copyEn.desc : copy.desc}
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-desk-ace">{lang == "en" ? copyEn.closeButton : copy.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default ModalDeskAce;
